export function buildMenuSidebarCollapse(menu) {
  const {sidebar} = window;
  const mini = mobile === 'smartphone' ? true : getLocalFlag('sidebar-mini');
  if (mini) {
    sidebar.toggleClass('sidebar-mini');
  }
  $('<li class="nav-item"><hr class="dropdown-divider"></li>').appendTo(menu);

  const li = $('<li class="nav-item"></li>').appendTo(menu);
  // TODO: Refactor to button tag for more semantically correct html
  const item = $(
    '<a href="#" class="nav-link link-light" aria-current="page"></a>'
  )
    .appendTo(li)
    .on(click, function (event) {
      event.preventDefault();
      $(this).children('svg').toggleClass('fa-angle-left fa-angle-right');

      const toggledMini = toggleLocalFlag('sidebar-mini');
      const text = toggledMini ? 'Ausklappen' : 'Einklappen';
      $(this).children('.link-title').text(text);

      sidebar.toggleClass('sidebar-mini');
    });

  const iconClass = mini ? 'fa-angle-right' : 'fa-angle-left';
  $(`<i class="icon fa-regular ${iconClass} fa-fw"></i>`).appendTo(item);

  const linkText = mini ? 'Ausklappen' : 'Einklappen';
  $('<span class="link-title" />').text(linkText).appendTo(item);

  sidebar
    .on('mouseover', function () {
      mini && $(this).addClass('hover');
    })
    .on('mouseout', function () {
      $(this).removeClass('hover');
    });

  // Side menu hidden in Tablet/Mobile view
  $(window).on('load, resize', function () {
    if (getScreenWidth() < 992) {
      sidebar.addClass('sidebar-mini').removeClass('hover');
    }
  });
}

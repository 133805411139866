import {menuListItemTypes} from './getMenuList.mjs';

export function buildMenuItems(menu, fieldsMenu, menuList) {
  let submenu;
  let submenu2;

  menuList.forEach(function (menuListItem, i) {
    const isDivider = menuListItem === menuListItemTypes.divider;
    const isPrevDivider = menuList[i - 1] === menuListItemTypes.divider;

    if (isDivider && isPrevDivider) {
      return;
    }
    if (isDivider) {
      $('<li class="nav-item"><hr class="dropdown-divider"></li>').appendTo(
        menu
      );
      return;
    }

    const type = menuListItem.slice(0, 1);
    const nextType = menuList[i + 1] ? menuList[i + 1].slice(0, 1) : undefined;
    const key = menuListItem.slice(1);
    const point = fieldsMenu[key];

    point.object = point.object ?? key;

    if (!point?.objectSub && point?.object !== key && !point.func) {
      point.objectSub = key;
    }

    point.label = getPointLabel(point, key);

    const objIdent = !point.noAjax
      ? [point.object, point.objectSub].filter(Boolean).join('_')
      : undefined;

    const clickable =
      (fields[key] ||
        point.object ||
        point.func ||
        point.jsFunc ||
        point.link ||
        user.admin) &&
      !point.noClick;

    let submenuFrame;
    const item = buildItem(key, point);

    if (type === menuListItemTypes.level1) {
      const li = $('<li class="nav-item" />').appendTo(menu);
      item
        .addClass('nav-link')
        .attr('aria-current', 'page')
        .attr('data-ident', objIdent)
        .appendTo(li);
      $('<span class="link-title" />').text(point.label).appendTo(item);

      let icon = point.icon ?? getParamSettings(point, 'icon');
      addIcon(item, icon, 'list', 'fa-lg fa-fw').prependTo(item);

      if (nextType === menuListItemTypes.level2) {
        item.attr({
          'data-bs-toggle': 'collapse',
          'data-bs-target': `#${key}-collapse`,
        });
        submenuFrame = $('<div class="collapse" />')
          .attr('id', `${key}-collapse`)
          .appendTo(li);
        submenu = $('<ul class="sub-menu flex-column gap-1" />').appendTo(
          submenuFrame
        );

        item.on(click, function (evt) {
          if (clickable) {
            menuClick(evt, point, key);
          } else if (point.clickFirst && !item.hasClass('collapsed')) {
            submenuFrame.find('.sub-link:first[data-overview]').trigger(click);
          }
        });
      } else if (clickable) {
        item.on(click, function (evt) {
          menuClick(evt, point, key);
        });
      }
      if (key === 'roleChanger') {
        submenuFrame = buildRoleChanger(li);
      }
    }

    if (type === menuListItemTypes.level2) {
      const li = $('<li />').appendTo(submenu);
      item.addClass('sub-link fw-normal').text(point.label).appendTo(li);
      if (objIdent) {
        item.attr('data-ident', objIdent);
      }
      if (clickable && !point.func) {
        item.attr('data-overview', true);
      }
      if (nextType === menuListItemTypes.level3) {
        item.attr({
          'data-bs-toggle': 'collapse',
          'data-bs-target': `#${key}-collapse`,
        });
        submenuFrame = $('<div class="collapse" />')
          .attr('id', `${key}-collapse`)
          .appendTo(li);
        submenu2 = $('<ul class="sub-menu flex-column gap-1" />').appendTo(
          submenuFrame
        );
      }
      if (clickable) {
        item.on(click, function (evt) {
          menuClick(evt, point, key);
        });
      }
    }

    if (type === menuListItemTypes.level3) {
      const li = $('<li />').appendTo(submenu2);
      item.addClass('sub-link fw-normal').text(point.label).appendTo(li);

      if (objIdent) {
        item.attr('data-ident', objIdent);
      }
      if (clickable) {
        item.on(click, function (evt) {
          menuClick(evt, point, key);
        });
      }
    }

    if (point.collapse && submenuFrame) {
      submenuFrame.addClass('show');
    }
  });
}

function getPointLabel(point, key) {
  const {role, user} = window;

  const userLanguageLabelKey = `label${ucfirst(user.language)}`;
  if (user?.language !== 'de' && point[userLanguageLabelKey]) {
    return point[userLanguageLabelKey];
  }

  const roleLabelKey = `label${ucfirst(role)}`;
  return point[roleLabelKey] ?? point.label ?? key;
}

function buildItem(key, point) {
  // TODO: Refactor to button tag for more semantically correct HTML
  const item = $('<a href="#" class="link-light" />')
    .attr('id', `menu${ucfirst(key)}`)
    .on('click', (event) => {
      event.preventDefault();
    });

  // tip + activate
  if (point.tip) {
    item.addTooltip(point.tip);
  }
  if (!point.activate) {
    return item;
  }

  item.addClass('activate-link');
  if (getLocalFlag(key)) {
    item.addClass('active active-fix');
    if (point.jsFunc && window[point.jsFunc]) {
      window[point.jsFunc]();
    }
  }

  return item;
}

import {buildMenuItems} from './buildMenuItems.mjs';
import {buildMenuSidebarCollapse} from './buildMenuSidebarCollapse.mjs';
import {buildMenuSearch} from './buildMenuSearch.mjs';
import {getMenuList} from './getMenuList.mjs';
import {buildMenuRoot} from './buildMenuRoot.mjs';

// eslint-disable-next-line no-redeclare,no-unused-vars
export function buildMenu(container, shouldClearMenu) {
  const fieldsMenu = $.extend(true, {}, window.fieldsMenu);

  const menu = buildMenuRoot(container, shouldClearMenu);
  const menuList = getMenuList(fieldsMenu);
  buildMenuItems(menu, fieldsMenu, menuList);

  buildMenuSidebarCollapse(menu);
  buildMenuSearch(container, shouldClearMenu);

  if (test && user.admin) {
    administrateMenu(menu, menuList);
  }
}

window.buildMenu = buildMenu;

export const menuListItemTypes = {
  divider: '|=',
  level1: '|',
  level2: '-',
  level3: ':',
};

// Returns a list of strings that defines the current menu structure via textual syntax
// e.g. |start, |saleMenu, -offer, -sale
export function getMenuList(fieldsMenu) {
  const menuList = getMenuListFromPoints(fieldsMenu).filter(
    // TODO: Not sure if this null filter is needed
    (menuListItem) => menuListItem !== null
  );
  return filterHiddenMenuListItems(fieldsMenu, menuList);
}

function getMenuListFromPoints(fieldsMenu) {
  const {role} = window;

  const roleKey = `menu${ucfirst(role)}`;
  const menuList = fieldsMenu[roleKey] ?? fieldsMenu.menu ?? [];

  // TODO: What is the meaning of this condition? When does it occur?
  if (menuList.length === 1 && fieldsMenu[menuList[0]]) {
    return fieldsMenu[menuList[0]];
  }

  return menuList;
}

function filterHiddenMenuListItems(fieldsMenu, menuList) {
  const {role} = window;

  // Used to hide submenus if the top level menu is hidden
  let shouldHideLowerLevelItems;

  return menuList.filter(function (menuListItem) {
    if (menuListItem === menuListItemTypes.divider) {
      return true;
    }

    const type = menuListItem.slice(0, 1);
    const key = menuListItem.slice(1);
    const point = fieldsMenu[key];

    if (type === menuListItemTypes.level1) {
      shouldHideLowerLevelItems = false;
    }

    const hidden =
      !point ||
      shouldHideLowerLevelItems ||
      (point.test && !test) ||
      (point.config === '1' && test && !config) ||
      (point.config === '0' && test && config) ||
      (isPlainObject(point.hidden) && checkWhere({}, point.hidden)) ||
      (isArray(point.hidden) && checkWhere({}, {role: point.hidden})) ||
      point.hidden === role ||
      (point.admin && !user.admin) ||
      point.hidden === 1;

    if (type === menuListItemTypes.level1) {
      shouldHideLowerLevelItems = hidden;
    }

    return !hidden;
  });
}

export function buildMenuSearch(container, shouldClearMenu) {
  if (shouldClearMenu) {
    return;
  }

  const search = $('<div class="search" role="search"></div>').appendTo(
    container
  );

  window.buildField(search, {
    field: 'search',
    placeholder: info.placeholder.searchMenu,
    fieldIdent: 'menu_search',
    save: function (value) {
      if (!value) {
        $('nav [data-ident]').removeClass('d-none');
        return;
      }

      $('nav [data-ident]').addClass('d-none');
      $('nav [data-ident]:icontains(' + value + ')').removeClass('d-none');
    },
  });
}

export function buildMenuRoot(container, shouldUpdate) {
  if (shouldUpdate) {
    return $('#menu').empty();
  }

  const menuCont = $('<div id="menu-cont" class="mb-auto"></div>').appendTo(
    container
  );
  new mdb.PerfectScrollbar(menuCont[0], {suppressScrollX: true});

  return $(
    '<ul id="menu" class="nav nav-pills flex-column gap-1" data-mdb-perfect-scrollbar="true">'
  ).appendTo(menuCont);
}
